import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { CheckboxPanel, Fieldset } from '@entur/form';
import { PrimaryButton } from '@entur/button';
import { ForwardIcon } from '@entur/icons';
import { Label } from '@entur/typography';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import PageHeader from '~/components/PageHeader';
import { inputpanel } from './props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <PageHeader mdxType="PageHeader" />
    <ImportStatement imports="CheckboxPanel" mdxType="ImportStatement" />
    <Playground __position={1} __code={'<Fieldset name=\"ticket-type\" label=\"Velg tilleggsprodukter:\">\n  <div\n    style={{\n      display: \'flex\',\n      flexDirection: \'column\',\n      gap: \'0.5rem\',\n      width: \'20rem\',\n    }}\n  >\n    <CheckboxPanel title=\"Setereservasjon\" value=\"seat\" />\n    <CheckboxPanel title=\"Hund\" value=\"dog\" />\n    <CheckboxPanel title=\"Sykkel\" value=\"flexi\">\n      <Label as=\"span\">Plass til sykkelen kan ikke garanteres</Label>\n    </CheckboxPanel>\n    <PrimaryButton>\n      Gå videre <ForwardIcon inline />\n    </PrimaryButton>\n  </div>\n</Fieldset>'} __scope={{
      props,
      DefaultLayout,
      Props,
      CheckboxPanel,
      Fieldset,
      PrimaryButton,
      ForwardIcon,
      Label,
      Playground,
      ImportStatement,
      PageHeader,
      inputpanel,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Fieldset name="ticket-type" label="Velg tilleggsprodukter:" mdxType="Fieldset">
    <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          width: '20rem'
        }}>
      <CheckboxPanel title="Setereservasjon" value="seat" mdxType="CheckboxPanel" />
      <CheckboxPanel title="Hund" value="dog" mdxType="CheckboxPanel" />
      <CheckboxPanel title="Sykkel" value="flexi" mdxType="CheckboxPanel">
        <Label as="span" mdxType="Label">Plass til sykkelen kan ikke garanteres</Label>
      </CheckboxPanel>
      <PrimaryButton mdxType="PrimaryButton">
        Gå videre <ForwardIcon inline mdxType="ForwardIcon" />
      </PrimaryButton>
    </div>
  </Fieldset>
    </Playground>
    <h2 {...{
      "id": "bruk"
    }}>{`Bruk`}</h2>
    <h3 {...{
      "id": "retningslinjer"
    }}>{`Retningslinjer`}</h3>
    <p><inlineCode parentName="p">{`CheckboxPanel`}</inlineCode>{` brukes i tilfeller der du har en liste over alternativer og brukeren kan velge ett eller flere alternativer.
Den er mest nyttig hvis man ønsker å legge til ekstra informasjon for hvert element, vil fremheve listen eller ønsker å øke
trykkflaten. Ellers kan det hende vanlig `}<a parentName="p" {...{
        "href": "https://design.entur.no/komponenter/skjemaelementer/checkbox"
      }}>{`Checkbox`}</a>{` fungerer bedre.
Merk at `}<inlineCode parentName="p">{`CheckboxPanel`}</inlineCode>{` ikke støtter indeterminate-tisltanden slik
`}<a parentName="p" {...{
        "href": "https://design.entur.no/komponenter/skjemaelementer/checkbox"
      }}>{`Checkbox`}</a>{` gjør.`}</p>
    <p>{`Hvis brukerne bare kan velge ett alternativ fra listen, er det mer passende å bruke
`}<a parentName="p" {...{
        "href": "https://design.entur.no/komponenter/skjemaelementer/radio-panel"
      }}>{`RadioPanel`}</a>{`. Om du ønsker at valget gjenspeiles eller lagres
med en gang bør du heller bruke en `}<a parentName="p" {...{
        "href": "https://design.entur.no/komponenter/skjemaelementer/switch"
      }}>{`Switch`}</a>{`.
Det anbefales å `}<em parentName="p">{`ikke`}</em>{` ha et forhåndsvalgt alternativ når du bruker checkbox panel. Alternaltivene bør vises i en logisk rekkefølge.`}</p>
    <h3 {...{
      "id": "test-ut-props"
    }}>{`Test ut props`}</h3>
    <Playground __position={2} __code={'<CheckboxPanel title=\"249,-\" value=\"non-flexi\" size=\"large\">\n  Ikke fleksibel\n</CheckboxPanel>'} __scope={{
      props,
      DefaultLayout,
      Props,
      CheckboxPanel,
      Fieldset,
      PrimaryButton,
      ForwardIcon,
      Label,
      Playground,
      ImportStatement,
      PageHeader,
      inputpanel,
      DefaultLayout,
      _frontmatter
    }} props={inputpanel} mdxType="Playground">
  <CheckboxPanel title="249,-" value="non-flexi" size="large" mdxType="CheckboxPanel">
    Ikke fleksibel
  </CheckboxPanel>
    </Playground>
    <h3 {...{
      "id": "fieldset"
    }}>{`Fieldset`}</h3>
    <ImportStatement imports="Fieldset" mdxType="ImportStatement" />
    <p><inlineCode parentName="p">{`Fieldset`}</inlineCode>{` lar deg gruppere flere `}<inlineCode parentName="p">{`CheckboxPanel`}</inlineCode>{`-er sammmen under en overskrift. Du kan sette en ledetekst/overskrift med `}<inlineCode parentName="p">{`label`}</inlineCode>{`-propen.`}</p>
    <p>{`Les mer om hvordan du bruker html-taggen `}<inlineCode parentName="p">{`fieldset`}</inlineCode>{` på sidene til `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/fieldset"
      }}>{`mdn web docs`}</a>{`;`}</p>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "checkboxpanel-"
    }}>{`<CheckboxPanel /`}{`>`}</h3>
    <ImportStatement imports="CheckboxPanel" mdxType="ImportStatement" />
    <Props of={CheckboxPanel} mdxType="Props" />
    <h3 {...{
      "id": "fieldset-"
    }}>{`<Fieldset /`}{`>`}</h3>
    <ImportStatement imports="Fieldset" mdxType="ImportStatement" />
    <Props of={Fieldset} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      